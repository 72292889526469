&.accommodation-detail {
    .lead-section {
        .info-nav {
            margin-bottom: 0;
        }

        .btn-back {
            @extend .mb-0;
        }

        .container-one-column {
            .container-holder {
                max-width: none;
            }
        }

        .info {
            @extend .mb-3;
        }
    }

    .content-section {
        .container-three-columns {
            .container-holder {
                @include media-breakpoint-down(lg) {
                    padding-left: 0;
                    padding-right: 0;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

        @include headings-style {
            @include stroke(3, $white);
            color: $primary;
        }
    }
}
.owl-carousel {
    .owl-nav {
        .owl-prev,
        .owl-next {
            font-size: 1.25rem;
        }
    } 
}

.owl-carousel.slider {
    .owl-dots {
        .owl-dot.active,
        .owl-dot:hover {
            span {
                background-color: $secondary;
                border-color: $secondary;
            }
        }
        .owl-dot {
            span {
                border-color: $secondary;
            }
        }
    }
}

